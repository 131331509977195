<template>
  <main>
    <WhoWeAre :aboutUs="aboutUs" />
    <Company :investmentDescription="investmentDescription" />
    <Partners />
    <SharingDetails :sharingDetails="sharingDetails" />
    <InvestmentInvitation :investmentInvitation="investmentInvitation" />
    <Money :moneyFlow="moneyFlow" />
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "app",
  components: {
    WhoWeAre: () => import("@/components/Home/WhoWeAre"),
    Partners: () => import("@/components/Home/OurCompanies"),
    Company: () => import("@/components/Home/Company"),
    SharingDetails: () => import("@/components/Home/SharingDetails"),
    InvestmentInvitation: () =>
      import("@/components/Home/InvestmentInvitation"),
    Money: () => import("@/components/Home/Money")
  },
  computed: {
    ...mapGetters(["settings"]),
    aboutUs() {
      return this.settings("about_us");
    },
    sharingDetails() {
      return this.settings("sharing_details");
    },
    investmentInvitation() {
      return this.settings("investment_invitation");
    },
    moneyFlow() {
      return this.settings("money_flow");
    },
    investmentDescription() {
      return this.settings("investment_description");
    }
  }
};
</script>
